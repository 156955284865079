import React, { Component } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrashAlt, faCheck } from "@fortawesome/pro-light-svg-icons"

import './ChoseAction.scss'

export default class ChoseAction extends Component {

    constructor(props) {
        super(props)
        this.state = { 
            check: false
        }
    }

    handleInputChange = () => {

        setTimeout(() => {
            this.setState({
                check: true
            })
            setTimeout(() => {
                this.props.getNextExcecis()
            }, 350);
        }, 450);
       
    }

    getCheckState = () => {

        const handleInputName = {
            0: 'Ich mag die Übung nicht',
            1: 'keine Zeit',
            3: 'Kein Gerät frei',
            4: 'Erschöpfung',
            5: 'Schmerz'
        }

        if (this.state.check === false) {
            return (
                Object.entries(handleInputName).map((name, index) => (
                    <div className="col-12 d-flex justify-content-center center-container-nextAction" key={index}>
                        <div className="form-check checkbox">
                            <input
                                type="hidden"
                                name={"privdisaccept-" + index}
                                className="form-control"
                                value="0"
                            />
                            <label className="form-check-label" htmlFor={"privdisaccept-" + index}>
                                <input 
                                    type="checkbox"
                                    className="form-check-input"
                                    name={"privdisaccept-" + index}
                                    value="1"
                                    id={"privdisaccept-" + index}
                                    onChange={this.handleInputChange}
                                />
                                <span className="cr">
                                    <FontAwesomeIcon className="cr-icon" icon={faCheck} />
                                </span> 
                                {name[1]}
                            </label>
                        </div>
                    </div>
                ))
            )
        } else {
            return (
                <div className="col-12 text-center check-icon-next">
                    <FontAwesomeIcon icon={faTrashAlt} />
                </div>
            )
        }
    }


    render() {
        return (
            <React.Fragment>
                <div className="col-12 text-left back-btn-list">
                    <div 
                        onClick={this.props.handleListClick}
                    >
                        Zurück
                    </div>
                </div>
                <div className="col-12 text-center mt-3 con-top-info">
                    <h5 className="font-weight-light">Übungsaustausch</h5>
                    <p>Gib uns eine Info wieso du diese Übung auslassen möchtest, nur so können wir dir in Zukunft eine bessere Übung bereit stellen.</p>
                </div>
                {this.getCheckState()}
            </React.Fragment>
            
        )
    }
}
