import React, { Component } from "react"

import { Form } from 'react-bootstrap';

import './Input.scss'

export default class WdhValue extends Component {

    render() {
        const { set, handleSubmit, stateSet, handleChange } = this.props

        return (
            <Form className="col-12 d-flex mb-2" key={set.id} name="TrainingsessionsSets" onSubmit={handleSubmit}>
                <Form.Group className="d-flex w-100 mb-0 d-flex flex-column" controlId="formWorkoutSetWdh">
                    <Form.Label className="label-set">Wiederholungen</Form.Label>
                    <Form.Control
                        name="wdh"
                        className="wi-set-input pl-3 text-center flex-fill"
                        type="number"
                        defaultValue={set.wdh}
                        isInvalid={stateSet.error.wdh}
                        onChange={handleChange}
                        onClick={this.handleFocus}
                        onKeyDown={handleChange}
                        pattern="/^-?\d*[.,]?\d*$/"
                    />
                </Form.Group>
                <span className="pr-2 pl-2 d-flex align-content-center flex-wrap"><br/>x</span>
                <Form.Group className="d-flex w-100 mb-0 flex-fill d-flex flex-column" controlId="formWorkoutSetValue">
                    <Form.Label className="label-set">Kilogramm:</Form.Label>
                    <Form.Control
                        name="value"
                        className="wi-set-input  text-center pl-3"
                        type="number"
                        defaultValue={set.value}
                        isInvalid={stateSet.error.value}
                        onChange={handleChange}
                        onClick={this.handleFocus}
                        onKeyDown={handleChange}
                        pattern="/^-?\d*[.,]?\d*$/"
                    />
                </Form.Group>
            </Form>
        )
    }
}
