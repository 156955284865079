import React, { useState, useEffect } from "react"
import { useAuth } from "../hooks/useAuth"
import UserAdapter from "../adapter/UserAdapter"

import debugLib from "debug"

const debug = debugLib("machbar:user:status")

const StatusContext = React.createContext();

const UserAdapterWrapper = new UserAdapter()

const StatusProvider = ({ children }) => {
    const [ authentication, user ] = useAuth("StatusProvider")

    const [ status, setStatus ] = useState({
        data: null,
        path: null
    })

    useEffect(() => {
        if (authentication !== null && user !== null) {
            debug("User Status will be set", {user, status})

            UserAdapterWrapper.setOnlineStatus({...status, user_id: user.id }).then((data) => {
                debug("User Status is set", data)
            })
        } else {
            debug("Authentication is not set, user status could not be set")
        }
    }, [status]);

    useEffect(() => {
        debug("Status Context is loaded")
    }, []);

    const providerValue = {
        setStatus
    }

    return <StatusContext.Provider value={providerValue} >
        {children}
    </StatusContext.Provider>
}

export { StatusProvider }
export default StatusContext