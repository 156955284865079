import React, { Component } from "react"

import Loading from "../../../services/Loading"
import { Image, Button } from 'react-bootstrap';
import { navigate } from "@reach/router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStopwatch, faFireAlt, faTrophyAlt } from "@fortawesome/pro-light-svg-icons"

import './WorkoutSingle.scss'


export default class WorkoutSingle extends Component {

    constructor(props) {
        super(props)
        this.state = {
            error: null,
            isLoaded: false,
        }
    }

    componentDidMount() {
        this.setState({
            isLoaded: true,
        })
    }

    gettime = () => {

        return 80;
    }

    handleClick = id => {
        this.props.startClick(id)
        return navigate(`/workout/${id}`)
    }

    render() {
        const { error, isLoaded } = this.state

        const workoutschedule = this.props.workout
        const { id } = workoutschedule

        if (!isLoaded) {
            return <Loading additionalClass="" />
        }

        if (error) {
            return <div>Error: {error.message}</div>
        }

        return (
            <React.Fragment>
                <div className="workout-wrapper mb-3">
                    <div className="workout-container row">
                        <div className="top col-12 pb-3 pt-3 justify-content-center ">
                            <h5>Ihr Trainingsplan:</h5>
                            <h3>{workoutschedule.name}</h3>
                        </div>
                        <div className="col-12 pb-3 d-flex justify-content-between text-center">
                            <div className="wk-intro-time">
                                <FontAwesomeIcon icon={faStopwatch} /><br/>{this.gettime()} Min.
                            </div>
                            <div className="wk-intro-time">
                                <FontAwesomeIcon icon={faTrophyAlt} /><br/>2. Level
                            </div>
                            <div className="wk-intro-time">
                                <FontAwesomeIcon icon={faFireAlt} /><br/>200 kcal.
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 workout-start-img">
                            <Image src="/img/workout-start.png" fluid />
                            <Button
                                onClick={() => this.handleClick(id)}
                                className="workout-start-btn">
                                    Los Geht's
                            </Button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
