import localforage from 'localforage'

localforage.config({
    name: 'Machbar',
    version: 1.0,
    storeName: "workout_data",
});

export const cleanUp = () => {
    localforage.clear().then(function() {
        // Run this code once the database has been entirely deleted.
        console.log('Database is now empty.');
    }).catch(function(err) {
        // This code runs if there were any errors
        console.log(err);
    });
}

export default localforage