import React, { Component } from "react"

import { Button } from 'react-bootstrap';

import ErrorNoWifi from './Error/NoWifi'
import './Action.scss'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons"

import { ExceciseAdapterWrapper } from '../../../../../context/ExceciseProvider'

export default class Action extends Component {

    constructor(props) {
        super(props)
        this.state = { 
            activeSets: [],
            singleActive: 0,
            wifi: true,
            loading: false
        }
    }

    handleNextSet = () => {
        const { singleSet, startDate } = this.props

        const content = {
            wdh: singleSet.wdh,
            value: singleSet.value,
            enddate: this.props.setDateFormat(new Date()),
            startdate: this.props.setDateFormat(startDate),
            exercises_workoutschedules_set_id: singleSet.id
        }

        this.setState({
            loading: true
        })

        ExceciseAdapterWrapper.setWorkoutSetData(content).then(() => {
            this.setState({
                wifi: true,
                loading: false
            })
            this.props.getNextExerciseSet()
        })
    }

    getNextBtn = () => {
        if (this.state.loading === false) {
            return (
                <Button 
                className="w-100 back-btn btn-next-set mt-1"
                onClick={this.handleNextSet} 
                >
                     Nächster Satz
                </Button>
            )
        } else {
            return (
                <Button 
                className="w-100 back-btn btn-next-set mt-1"
                >
                    <FontAwesomeIcon icon={faSpinnerThird} spin />  Nächster Satz
                </Button>
            )
        }
    }

    render() {
        const { handleSetValuesClick } = this.props

        if (this.state.wifi === false) {
            return (
               <ErrorNoWifi handleClick={this.handleNextSet} />
            )
        }

        return (
            <div className="col-12 p-0 mt-5">
                <div className="col-12">
                    <Button
                        className="w-100 back-btn input-btn mt-1"
                        onClick={handleSetValuesClick} 
                    >
                        Eingabe
                    </Button>
                   {this.getNextBtn()}
                </div>
            </div>
        )
    }
}
