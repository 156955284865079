import React, { Component } from "react"

import { Row, Col } from 'react-bootstrap';
import { navigate } from "@reach/router";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHeart } from "@fortawesome/pro-light-svg-icons"

import { Auth } from "@interweberde/prima-core"

import './Logout.scss'

export default class Logout extends Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
      
        setTimeout(() => {
            navigate('/').then(() => {
                Auth.logout()
            })
        }, 550);
    }

    render() {

        return (
            <React.Fragment>
                <Row className="mt-5 mb-5">
                    <Col className="text-center">
                        <FontAwesomeIcon className="icon-logout" icon={faHeart} />
                        <h2 className="font-weight-light">Bis bald!</h2>
                    </Col>
                </Row>
            </React.Fragment>
        )
    }
}
