import React, { Component } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStop, faPlay } from "@fortawesome/pro-light-svg-icons"

import './Workout.scss'

export default class Workout extends Component {

    constructor(props) {
        super(props)
        this.state = { 
            playPause: true,
            dateNow: null,
            dateTimer: '',
            dateObject: null
        }
    }

    getIconPlayStop = () => {
        this.setState({
            playPause: false,
            dateNow: new Date(),
        })

        this.props.setStartDate()

        this.props.handleStopBreak()

        const countUp = setInterval(() => {
        
            let currentDate = Date.now();
            let distance = currentDate - this.state.dateNow;
        
            let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((distance % (1000 * 60)) / 1000);

            if (seconds < 10) {
                seconds = '0' + seconds
            }

            if (minutes < 10) {
                minutes = '0' + minutes
            }

            this.setState({
                dateTimer: `${minutes}:${seconds}`
            })
        
          }, 1000);

          this.setState({
            dateObject: countUp
        })
    }

    componentWillUnmount () {
        clearInterval(this.state.dateObject);
    }

    handleSaveDateWorkout = () => {
        clearInterval(this.state.dateObject);

        let currentDate = Date.now();
        this.props.setTimeSetDuration(currentDate - this.state.dateNow)

        this.props.saveDateWorkout(this.state.dateNow)
        this.props.endSet()
        this.props.handleActionClick()
    }

    getPlayStopContent = () => {
        if (this.state.playPause === true) {
            return (
                <div className="col-12 text-center mt-4">
                    <div 
                        className="workout-timer-start play"
                        onClick={this.getIconPlayStop}
                    >
                        <FontAwesomeIcon icon={faPlay} />
                    </div>
                </div>
        )
        } else {
            return (
                <React.Fragment> 
                   <div className="col-12 text-center mt-4">
                        <div 
                            className="workout-timer-start stop"
                            onClick={this.handleSaveDateWorkout}
                        >
                            <FontAwesomeIcon icon={faStop} />
                        </div>
                    </div>
                    <div className="col-12 text-center mt-4">
                        <h3>{this.state.dateTimer}</h3>
                    </div>
                </React.Fragment>
            )
        }
    }


    getHeadlineText = () => {
        const { wdh, value } = this.props.currentExcecisSets

        const { getSetsDone, singleExcecis, getFrontendTime } = this.props


        if (singleExcecis.type === 1) {
            return (
                <React.Fragment>
                    <strong>Satz {getSetsDone + 1}.</strong>
                    &nbsp;&nbsp;-&nbsp;&nbsp;{wdh} Wdh.&nbsp;&nbsp;-&nbsp;&nbsp;{value}kg
                </React.Fragment>
            )
        } else if (singleExcecis.type === 2) {
            return (
                <React.Fragment>
                    <strong>Satz {getSetsDone + 1}.</strong>
                    &nbsp;&nbsp;-&nbsp;&nbsp;{wdh} Wdh.&nbsp;&nbsp;-&nbsp;&nbsp;{getFrontendTime(value)} min.
                </React.Fragment>
            )
        } else if (singleExcecis.type === 3) {
            return (
                <React.Fragment>
                    <strong>Satz {getSetsDone + 1}.</strong>
                    &nbsp;&nbsp;-&nbsp;&nbsp;{getFrontendTime(value)} min.&nbsp;&nbsp;-&nbsp;&nbsp;{wdh}Wdh.
                </React.Fragment>
            )
        } else if (singleExcecis.type === 4) {
            return (
                <React.Fragment>
                    <strong>Satz {getSetsDone + 1}.</strong>
                    &nbsp;&nbsp;-&nbsp;&nbsp;{wdh}Wdh.
                </React.Fragment>
            )
        } else if (singleExcecis.type === 5) {
            return (
                <React.Fragment>
                    <strong>Satz {getSetsDone + 1}.</strong>
                    &nbsp;&nbsp;-&nbsp;&nbsp;{wdh} Wdh.&nbsp;&nbsp;-&nbsp;&nbsp;{value}
                </React.Fragment>
            )
        }
    }

    render() {
        return (
        <div className="col-12 p-0">
            <div className="col-12 text-center mt-2 text-headline-playstop">
                <small>Ready Set GO!</small> <br/>
                {this.getHeadlineText()}
            </div>
            {this.getPlayStopContent()}
        </div>
       )
    }
}
