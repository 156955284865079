import React, { Component } from "react"

import { Form } from 'react-bootstrap';

import './Input.scss'

export default class MinWdh extends Component {

    constructor(props) {
        super(props)
        this.state = {
           time: null
        }
    }

    componentDidMount () {
        const { getTimeDuration } = this.props

        this.setState({
            time: getTimeDuration
        })
    }

    getSecondTime = (timeSet) => {
        return Math.floor((timeSet % (1000 * 60)) / 1000);
    }

    getMinuteTime = (timeSet) => {
        return Math.floor((timeSet % (1000 * 60 * 60)) / (1000 * 60));
    }

    handleMinuteChange = (e) => {
        let minute = Math.floor((e.target.value * 60) * 1000)

        let second = this.getSecondTime(this.state.time) * 1000

        this.setState({
            time: minute + second
        })
        this.props.setValueData(minute + second)
    }

    handleSecondChange = (e) => {
        let minute = Math.floor((this.getMinuteTime(this.state.time) * 60) * 1000)

        let second = e.target.value * 1000

        this.setState({
            time: minute + second
        })
        this.props.setValueData(minute + second)
    }

    render() {
        const { set, handleSubmit, getTimeDuration, stateSet, handleChange } = this.props

        return (
            <Form className="col-12 d-flex mb-2" key={set.id} name="TrainingsessionsSets" onSubmit={handleSubmit}>
                <Form.Group className="d-flex w-100 mb-0 d-flex flex-column" controlId="formWorkoutSetMinute">
                    <Form.Label className="label-set">Minuten</Form.Label>
                    <Form.Control 
                        defaultValue={this.getMinuteTime(getTimeDuration)}
                        as="select"
                        name="minute"
                        className="wi-set-input text-center flex-fill"
                        onChange={this.handleMinuteChange}
                    >
                        {[...Array(60)].map((x, i) =>
                            {
                                let number = i < 10 ? '0' + i : i
                                return <option value={i} key={i} >{number}</option>
                            }
                        )}
                    </Form.Control>
                </Form.Group>
                <span className="pr-2 pl-2 d-flex align-content-center flex-wrap"><br/>:</span>
                <Form.Group className="d-flex w-100 mb-0 flex-fill d-flex flex-column" controlId="formWorkoutSetSecnds">
                    <Form.Label className="label-set">Sekunden:</Form.Label>
                    <Form.Control
                        defaultValue={this.getSecondTime(getTimeDuration)}
                        as="select"
                        name="second"
                        className="wi-set-input text-center flex-fill"
                        onChange={this.handleSecondChange}
                    >
                        {[...Array(60)].map((x, i) =>
                            {
                                let number = i < 10 ? '0' + i : i
                                return <option value={i} key={i} >{number}</option>
                            }
                        )}
                    </Form.Control>
                </Form.Group>
                <span className="pr-2 pl-2 d-flex align-content-center flex-wrap"><br/>x</span>
                <Form.Group className="d-flex w-100 mb-0 d-flex flex-column" controlId="formWorkoutSetWdh">
                    <Form.Label className="label-set">Wiederholungen</Form.Label>
                    <Form.Control
                        name="wdh"
                        className="wi-set-input text-center flex-fill"
                        type="number"
                        defaultValue={set.wdh}
                        isInvalid={stateSet.error.wdh}
                        onChange={handleChange}
                        onClick={this.handleFocus}
                        onKeyDown={handleChange}
                        pattern="/^-?\d*[.,]?\d*$/"
                    />
                </Form.Group>
            </Form>
        )
    }
}
