import React, { useContext } from 'react';
import AuthContext from "../../context/AuthProvider"

import { Row, Col } from 'react-bootstrap';

import StatistikWrapper from './StatistikWrapper/StatistikWrapper'
import WorkoutWrapper from './WokoutWrapper/WorkoutWrapper'

import './Dashboard.scss'

function Dashboard() {

    const { user } = useContext(AuthContext)

    return (
        <React.Fragment>
            <Row>
                <Col xs={12} className="mt-1 pt-5 pb-5 dashboard-img">
                    <h4>Hallo {user ? user.name : ''},</h4>
                    <p>Schön das Sie wieder bei uns sind, wollen Sie heute ein neues Workout starten?</p>
                </Col>
            </Row>
            <Row className="mt-1">
                <Col xs={12}>
                    <h4 className="headline-intro"><small>Deine</small> Übersicht</h4>
                </Col>
                <Col xs={12} md={6}>
                    <WorkoutWrapper />
                </Col>
                <Col xs={12} md={6}>
                    <StatistikWrapper />
                </Col>
                <Col xs={12} className="mt-2">
                    <h4 className="headline-intro-kontakt">
                        Kontakt<small>Frag uns alles was du möchtest</small>
                    </h4>
                    <p className="text-intro">
                        Braucht du Hilfe bei einer Übung oder sind bestimmte Hilfsmittel nicht beii der Station vorhanden? Dann komm auf uns zu und Frag uns einfach, wir sind immer vor Ort. 
                        <br/><br/>
                        <strong>Dein Machbar TEAM</strong>
                    </p>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default Dashboard