import React from 'react';

import { Row, Col, Container } from 'react-bootstrap'
import { Link } from "@reach/router";

import Footer from '../../Footer/Footer'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons"

import './NotFound.scss'

function NotFound() {

    return (
        <React.Fragment>
            <Container>
                <Row className="mt-2 mb-3">
                    <Col>
                        <h2 className="font-weight-light">Seite nicht gefunden</h2>
                        <p>Sollte dieses Problem erneut auftreten melden Sie sich bitte bei uns.</p>
                    </Col>
                    <Col xs={12} className="text-center mb-3">
                        <FontAwesomeIcon size={'6x'} icon={faExclamationTriangle} />
                    </Col>
                    <Col xs={12} className="mt-3">
                        <Link 
                            to="/"
                            className="btn-NotFound"
                        >
                            Startseite
                        </Link>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </React.Fragment>
    )
}

export default NotFound