import React, { useContext, useState, useEffect } from 'react';
import { navigate } from "@reach/router";
import  Joi from '@hapi/joi'

import { Row, Col, Form, Button } from 'react-bootstrap';

import AuthContext from "../../context/AuthProvider"
import StatusContext from "../../context/StatusProvider"
import UserAdapter from '../../adapter/UserAdapter'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons"

import './Profile.scss'

const UserAdapterWrapper = new UserAdapter()

const schema = Joi.object({
    name: Joi.string()
        .alphanum()
        .min(2)
        .max(35)
        .required()
        .error((errors) => {
            errors.map((err) => {
                if (String(err.code) === 'string.min') {
                    err.message = `Ihr Vorname muss mindestens 2 Zeichen lang sein! (${err.local.value})`
                } else if (String(err.code) === 'string.max') {
                    err.message = `Ihr Vorname darf nicht länger als 35 Zeichen lang sein! (${err.local.value})`
                } else if (String(err.code) === 'string.required') {
                    err.message = `Der Vorname wird benötigt (${err.local.value})`
                } else {
                    err.message = 'Bitte korrigieren Sie Ihre Eingabe!'
                }
            })

            return errors
        }),

    surname: Joi.string()
        .alphanum()
        .min(2)
        .max(35)
        .required()
        .error((errors) => {
            errors.map((err, index) => {
                if (String(err.code) === 'string.min') {
                    err.message = `Ihr Nachname muss mindestens 2 Zeichen lang sein! (${err.local.value})`
                } else if (String(err.code) === 'string.max') {
                    err.message = `Ihr Nachname darf nicht länger als 35 Zeichen lang sein! (${err.local.value})`
                } else if (String(err.code) === 'string.required') {
                    err.message = `Der Nachname wird benötigt (${err.local.value})`
                } else {
                    err.message = 'Bitte korrigieren Sie Ihre Eingabe!'
                }
            })

            return errors
        }),

    email: Joi.string()
        .email({ 
            minDomainSegments: 2, 
            tlds: { allow: ['com', 'net', 'de', 'org'] }
        })
})

const defaultValidate = {
    surname: null, name: null, email: null
}

function Profile() {
    const { user, setUser } = useContext(AuthContext)

    const { setStatus } = useContext(StatusContext)

    const [ profile, setProfile ] = useState(user || {})
    const [ error, setError ] = useState(defaultValidate)
    const [ btnError, setBtnError ] = useState()

    const [ loading, setLoading ] = useState(true)

    const [ userLoading, setUserLoading ] = useState(true)

    useEffect(() => {
        if (user !== null && userLoading) {
            setStatus({
                data: 'Benutzerprofil',
                path: 'profile'
            })

            setProfile(user)
            setUserLoading(false)
        }
    }, [user]);

    useEffect(() => {
        setBtnError(false)
        setError(defaultValidate)
        if (!userLoading) {
            try {
                const value = schema.validate({ 
                    name: profile.name,
                    surname: profile.surname,
                    email: profile.email
                });
    
                if (value.error) {
                    setBtnError(true)
                    setError(
                        {
                            ...defaultValidate,
                            [value.error.details[0].context.key]: value.error.message
                        }
                    )
                }
            }
            catch (err) {
                console.log(err)
            }
        }
       
    }, [profile]);

    const handleSubmit = event => {
        event.preventDefault();

        const userdata = {
            surname: profile.surname,
            name: profile.name,
            email: profile.email,
        }

        setUser({...user, ...userdata})

        setLoading(false)

        UserAdapterWrapper.setProfileData(user.id, userdata).then(() => {
            setLoading(true)
            return navigate('/')
        })
    };

    const getSubmitBtn = () => {
        if (loading) {
            return (
                <Button 
                    type="submit"
                    onClick={handleSubmit}
                    disabled={!!btnError}
                    className="btn-profile-save mt-2">
                        Speichern
                </Button>
            )
        } else {
            return (
                <Button 
                    type="button"
                    className="btn-profile-save mt-2">
                        <FontAwesomeIcon icon={faSpinnerThird} spin /> Speichern
                </Button>
            )
        }
    }

    return (
        <React.Fragment>
            <Row className="mt-2">
                <Col>
                    <h2 className="font-weight-light"><small>Dein</small> Benutzerprofil</h2>
                </Col>
            </Row>
            <Form>
                <Row>
                    <Col>
                        <Form.Group controlId="formGroupUsername">
                            <Form.Label className="profile-label">Benutzername</Form.Label>
                            <Form.Control 
                                type="username" 
                                placeholder="Benutzername"
                                autoComplete="username"
                                maxLength="64"
                                autoCapitalize="off"
                                spellCheck="false"
                                defaultValue={profile.username}
                                disabled
                            />
                        </Form.Group>
                        <Form.Group controlId="formGroupEmail">
                            <Form.Label className="profile-label">E-Mail Adresse</Form.Label>
                            <Form.Control 
                                type="email" 
                                placeholder="E-Mail"
                                defaultValue={profile.email}
                                isInvalid={!!error.email}
                                onChange={(e) => setProfile(
                                    {...profile, email: e.target.value})}
                                disabled={!!userLoading}
                            />
                            <span className="profile-error">
                                {error.email ? error.email: null}
                            </span>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <h5 className="font-weight-light">Persöhnliche Angaben</h5>
                    </Col>
                    <Col xs={6}>
                        <Form.Group controlId="formGroupName">
                            <Form.Label className="profile-label">Vorname</Form.Label>
                            <Form.Control 
                                type="name" 
                                placeholder="Vorname"
                                autoComplete="username"
                                autoCapitalize="off"
                                spellCheck="false"
                                defaultValue={profile.name}
                                isInvalid={!!error.name}
                                onChange={(e) => setProfile(
                                    {...profile, name: e.target.value})}
                                disabled={!!userLoading}
                            />
                            <span className="profile-error">
                                {error.name ? error.name: null}
                            </span>
                        </Form.Group>
                    </Col>
                    <Col xs={6}>
                        <Form.Group controlId="formGroupSurname">
                            <Form.Label className="profile-label">Nachname</Form.Label>
                            <Form.Control 
                                type="surname" 
                                placeholder="Nachname"
                                autoComplete="username"
                                autoCapitalize="off"
                                spellCheck="false"
                                isInvalid={!!error.surname}
                                defaultValue={profile.surname}
                                onChange={(e) => setProfile(
                                    {...profile, surname: e.target.value})}
                                disabled={!!userLoading}
                            />
                            <span className="profile-error">
                                {error.surname ? error.surname: null}
                            </span>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-5">
                    <Col>
                    {getSubmitBtn()}
                    </Col>
                </Row>
            </Form>
    </React.Fragment>
    );
}

export default Profile