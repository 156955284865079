import React, { Component } from "react"

import { Button } from 'react-bootstrap';

import './ActionNext.scss'

export default class ActionNext extends Component {

    constructor(props) {
        super(props)
        this.state = { 
            activeSets: [],
            singleActive: 0
        }
    }

    render() {
        const { handleSetPushClick, handleChoseNext } = this.props

        return (
            <React.Fragment>
                <div className="col-12 text-left back-btn-list">
                    <div 
                        onClick={this.props.handleListClick}
                    >
                        Zurück
                    </div>
                </div>
                <div className="col-12 p-0 mt-5">
                    <div className="col-12">
                        <Button
                            className="w-100 back-btn btn-next-skip mt-1"
                            onClick={handleSetPushClick} 
                        >
                            Später
                        </Button>
                        <Button
                            className="w-100 back-btn btn-next-set mt-1"
                            onClick={handleChoseNext} 
                        >
                        Auslassen
                        </Button>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
