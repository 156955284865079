import React from 'react';

import { Nav, Image } from 'react-bootstrap';
import { Link } from "@reach/router"

import './Header.scss'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHome, faUserAlt, faSignOut } from "@fortawesome/pro-light-svg-icons"

function Dashboard() {

  return (
    <Nav defaultActiveKey="/" className="justify-content-between" as="ul">
        <Link to="/" >
            <Image 
                src="/img/Workout.png"
                className="nav-image justify-content-start"
                fluid 
            />
        </Link>
        <div className="d-flex justify-content-end">
            <Nav.Item as="li">
                <Link className="nav-link" to="/" >
                    <FontAwesomeIcon icon={faHome} />
                </Link>
            </Nav.Item>
            <Nav.Item as="li">
                <Link className="nav-link" to="/profile" >
                    <FontAwesomeIcon icon={faUserAlt} />
                </Link>
            </Nav.Item>
            <Nav.Item as="li">
                <Link className="nav-link" to="/logout" >
                    <FontAwesomeIcon icon={faSignOut} />
                </Link>
            </Nav.Item>
        </div>
    </Nav>
  );
}

export default Dashboard