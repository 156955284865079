import React, { Component, Suspense } from "react"

import { Button } from 'react-bootstrap';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDoubleRight, faClipboardList, faVideo } from "@fortawesome/pro-light-svg-icons"

import Loading from "../../../../../services/Loading"
import './Index.scss'

const TypeMin = React.lazy(() => import('./Type/Min'))
const TypeWdhValue = React.lazy(() => import('./Type/WdhValue'))
const TypeMinWdh = React.lazy(() => import('./Type/MinWdh'))
const TypeWdh = React.lazy(() => import('./Type/Wdh'))
const TypeWdhOption = React.lazy(() => import('./Type/WdhOption'))

const components = {
    1: TypeWdhValue,
    2: TypeMin,
    3: TypeMinWdh,
    4: TypeWdh,
    5: TypeWdhOption,
}

export default class Index extends Component {

    constructor(props) {
        super(props)
        this.state = { 
            activeSets: [],
            singleActive: 0
        }
    }

    getExcecisTypeData = () => {
        const { singleExcecis, sets, getFrontendTime } = this.props

        const typeKey = (typeof singleExcecis.type !== 'undefined') ? singleExcecis.type : 1;

        const ComponentTypeName = components[typeKey]

        return (
            <Suspense fallback={<Loading />}>
                <ComponentTypeName 
                    sets={sets}
                    getFrontendTime={getFrontendTime}
                />
            </Suspense>
        )
    }

    render() {
        const { 
            handleActionNextClick, 
            handleVideoClick, 
            handleInfoClick, 
        } = this.props

        return (
            <React.Fragment>
                <div className="col-12 mt-1">
                    <div className="row mt-1">
                        <div className="col-4 text-center pr-0">
                            <Button 
                                className="w-100 btn-index-workout biw-first"
                                onClick={handleVideoClick}
                            >
                            <FontAwesomeIcon icon={faVideo} />
                            </Button>
                        </div>
                        <div className="col-4 text-center">
                            <Button
                                className="w-100 btn-index-workout biw-m"
                                onClick={handleActionNextClick} 
                            >
                            <FontAwesomeIcon icon={faChevronDoubleRight} />
                            </Button>
                        </div>
                        <div className="col-4 text-center pl-0">
                            <Button 
                                className="w-100 btn-index-workout biw-last"
                                onClick={handleInfoClick}
                            >
                            <FontAwesomeIcon icon={faClipboardList} />
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <p className="text-todo-workout text-center">
                        Führe so viele Wiederholungen aus, wie du gerade noch sauber schaffst.
                    </p>
                    <h6 className="headline-todo-workout">Trainingssätze:</h6>
                </div>
                <div className="col-12">
                    <div className="list-sets-bottom">
                        <div className="col-12">
                            <p>Ihre Trainingsätze für heute:</p>
                            {this.getExcecisTypeData()}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
