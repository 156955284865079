import React from 'react';
import './App.scss';
import { Router } from "@reach/router";
import { hot } from "react-hot-loader"

import { ApiAdapter } from '@interweberde/prima-core';

import { AuthProvider } from "./context/AuthProvider";
import { StatusProvider } from "./context/StatusProvider"

import Route from './services/Route'

import Dashboard from './Workout/Dashboard/Dashboard'
import Profile from './Workout/Profile/Profile'
import Exercise from './Workout/Exercise/Exercise'
import Logout from './Workout/Logout/Logout'
import Workout from './Workout/Workout/Workout'
import Statistik from './Workout/Statistik/WeekStatistik/WeekStatistik'

import NotFound from './Workout/Error/NotFound/NotFound'

ApiAdapter.setBaseUrl('http://pflegbar.einfachistlecker.de/')


const App = () => (
    <React.StrictMode>
        <AuthProvider>
            <StatusProvider>
                <Router id="router">
                    <Route path="/" component={Dashboard} authenticated exact/>
                    <Route path="/workout" component={Workout} authenticated exact />
                    <Route path="/workout/:exerciseId" component={Exercise} authenticated />
                    <Route path="/statistik" component={Statistik} authenticated />
                    <Route path="/profile" component={Profile} authenticated />
                    <Route path="/logout" component={Logout} authenticated />

                    <NotFound default />
                </Router>
            </StatusProvider>
        </AuthProvider>
    </React.StrictMode>
)

export default hot(module)(App)
