import React from "react"
import { useAuth } from "../hooks/useAuth"

const defaultValue = {
    authentication: null,
    user: null 
}

const AuthContext = React.createContext(defaultValue);

const AuthProvider = ({ children }) => {
    const [ authentication, user, setUser ] = useAuth("AuthProvider")

    const providerValue = {
        authentication,
        user,
        setUser
    }

    return <AuthContext.Provider value={providerValue}>
        {children}
    </AuthContext.Provider>
}

export { AuthProvider }
export default AuthContext