import React, { Component } from 'react';

export default class CountdownClock extends Component {

  constructor(props) {
    super(props)

    this.timer = React.createRef();
    this.background = React.createRef();
    this.component = React.createRef();

    this.state = {
        seconds: 30,
        radius: 200,
        innerRadius: null,
        fraction: null,
        content: null,
        canvas: null,
        timeoutIds: [],
        color: '#000',
        alpha: 1,
        timeFormat: 'hms',
        fontSize: 'auto',
        scale: window.devicePixelRatio || 1,
        tickPeriod: 1000
    }
}

  componentDidUpdate(prevProps) {
    if (prevProps.seconds !== this.props.seconds) {
        this.setState({
            seconds:  this._startSeconds()
        })
      this._stopTimer();
      this._setupTimer();
    }

    if (this.state.color !== this.props.color) {
      this._drawBackground();
      this._updateCanvas();
    }
  }

  componentDidMount() {
    this.setState({
        seconds:  this._startSeconds()
    })
    return this._setupTimer();
  }

  componentWillUnmount() {
    return this._cancelTimer();
  }

  _startSeconds() {
    return this.props.seconds - 0.01; 
  }

  _setupTimer() {
    this._setScale();
    this._setupCanvases();
    this._drawBackground();
    this._drawTimer();

    return this._startTimer();
  }

  _updateCanvas() {
    this._clearTimer();
    return this._drawTimer();
  }

  _setScale() {
    this.setState({
        radius:  (this.props.size / 2),
        fraction: (2 / this.state.seconds),
        tickPeriod:  this._calculateTick(),
    })

    const innerRadius =  this.props.weight ?
    this.state.radius - this.props.weight
    :
    this.state.radius / 1.3;

    return this.setState({
        innerRadius
    })
    
  }

  _refreshTimer() {
    this.setState({
        seconds:  this._startSeconds()
    })
    this.props.isbreakRefresh()
  }

  _calculateTick() {
    // Tick period (milleseconds) needs to be fast for smaller time periods and slower
    // for longer ones. This provides smoother rendering. It should never exceed 1 second.
    const tickScale = 1.8;
    const tick = this.state.seconds * tickScale;
    if (tick > 1000) { return 1000; } else { return tick; }
  }

  _setupCanvases() {
    if (this._background && this._timer) { return; }

    this._background = this.background.current.getContext('2d');
    this._background.scale(this.state.scale, this.state.scale);

    this._timer = this.timer.current.getContext('2d');
    this._timer.textAlign = 'center';
    this._timer.textBaseline = 'middle';
    this._timer.scale(this.state.scale, this.state.scale);

    if (this.props.onClick != null) {
      return this.component.current.addEventListener('click', this.props.onClick);
    }
  }

  _startTimer() {
    this.setState({
        timeoutIds: this.state.timeoutIds.push(setTimeout( () =>  this._tick()), 200),
    })
  }

  _pauseTimer() {
    this._stopTimer();
    return this._updateCanvas();
  }

  _stopTimer() {
    return Array.from(this.state.timeoutIds).map((timeout) =>
      clearTimeout(timeout));
  }

  _tick() {
    const start = Date.now();
    setTimeout(( () => {

        if (this.props.breakRefresh) {
            this._refreshTimer()
        }

        const duration = (Date.now() - start) / 1000;

        this.setState({
            seconds: (this.state.seconds - duration),
        })

        if (this.state.seconds <= 0) {
        this.setState({
            seconds: 0,
        })
        this._handleComplete();
        return this._clearTimer();
        } else {
        this._updateCanvas();
        return this._tick();
        }
    }
    ), this.state.tickPeriod)
  }

  _cancelTimer() {
    this._stopTimer();

    if (this.props.onClick != null) {
      return this.component.current.removeEventListener('click', this.props.onClick);
    }
  }

  _handleComplete() {
    if (this.props.onComplete) {
      return this.props.onComplete();
    }
  }

  _clearBackground() {
    return this._background.clearRect(0, 0, this.timer.current.width, this.timer.current.height);
  }

  _clearTimer() {
    if (this.timer.current != null) {
      return this._timer.clearRect(0, 0, this.timer.current.width, this.timer.current.height);
    }
  }

  _drawBackground() {
    this._clearBackground();
    this._background.beginPath();
    this._background.globalAlpha = this.state.alpha / 3;
    this._background.fillStyle = this.props.color;
    
    this._background.arc(this.state.radius, this.state.radius,this.state.radius, 0, Math.PI * 2, false);
    this._background.arc(this.state.radius, this.state.radius, this.state.innerRadius , Math.PI * 2, 0, true);
    this._background.closePath();
    return this._background.fill();
  }

  _formattedTime() {
    let left;
    const decimals = ((left = (this.state.seconds < 10) && this.props.showMilliseconds)) != null ? left : {1 : 0};

    return (Math.floor(this.state.seconds * 10) / 10).toFixed(decimals);
  }

  fontSize = () => {
    if (this.props.fontSize === 'auto') {
      const scale = 2;
      const size = this.state.radius / scale;
      return `${size}px`;
    } else {
      return this.props.fontSize;
    }
  }

  _drawTimer() {
    const percent = (this.state.fraction * this.state.seconds) + 1.5;
    const formattedTime = this._formattedTime();
    const text = (this.props.paused && (this.props.pausedText != null)) ? this.props.pausedText : formattedTime;

    // Timer
    this._timer.globalAlpha = this.state.alpha;
    this._timer.fillStyle = this.props.color;
    this._timer.font = `${this.fontSize(formattedTime)} ${this.props.font}`;

    this._timer.fillText(text, this.state.radius, this.state.radius);
    this._timer.beginPath();
    this._timer.arc(this.state.radius, this.state.radius, this.state.radius, Math.PI * 1.5, Math.PI * percent, false);
    this._timer.arc(this.state.radius, this.state.radius, this.state.innerRadius, Math.PI * percent, Math.PI * 1.5, true);
    this._timer.closePath();
    return this._timer.fill();
  }

  render() {
    const canvasStyle = { 
        position: "absolute",
        width: "auto", 
        height: this.props.height,
    };
    const canvasProps = { style: canvasStyle, height: this.props.size * this.state.scale, width: this.props.size * this.state.scale };

    return (
        <div className="row">
            <div ref={this.component} className={this.props.addClass} >
                <canvas ref={this.background} {...canvasProps}></canvas>
                <canvas ref={this.timer} {...canvasProps}></canvas>
            </div>
        </div>
    )
  }
}

