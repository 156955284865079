/* eslint-disable react/no-unused-state */
import React, { Component } from "react"

import Loading from "../services/Loading"

import localforage from '../services/WorkoutStorage'
import ExceciseAdapter from '../adapter/ExceciseAdapter'

export const ExceciseContext = React.createContext()

export const ExceciseAdapterWrapper = new ExceciseAdapter()

export class ExceciseProvider extends Component {

    constructor(props) {
        super(props)
        this.state = {
            error: null,
            isLoaded: false,
            ExcecisState: 'main',
            showSetList: 'list',
            singleExcecis: [],
            singleExcecisSets: [],
            currentExcecisSets: [],
            setsLoad: false,
            currentExcecis: null,
            totalExcecis: 0,
            totalExcecisCount: 1,
            getSetsDone: 0,
            startDate: null,
            getTimeDuration: null,
            break: false,
            breakRefresh: false,
            workoutscheduleId: null,
            workoutschedule: {}
        }
    }

    startBreak = () => {
        if (this.state.break) {
            this.setState({
                breakRefresh: true
            })
        } else {
            this.setState({
                break: true
            })
        }
    }

    isbreakRefresh = () => {
        this.setState({
            breakRefresh: false
        })
    }

    removeBreak = () => {
        this.setState({
            break: false
        })
    }

    saveDateWorkout = (date) => {
        console.log(date)
    }


    handleSetPushClick = () => {
        this.setState({
            showSetList: 'list'
        })

        const { workoutscheduleId, workoutschedule, currentExcecis } = this.state
        const keys = Object.keys(workoutschedule)

        if (workoutscheduleId !== null) {
            let found = keys.find(function(element){
                return Number(element) === Number(workoutscheduleId)
            })

            if (Number(found) === Number(workoutscheduleId)) {
                const exercisesClone = [...this.state.workoutschedule[workoutscheduleId].exercises]

                let getIndex = exercisesClone.findIndex((element)  => {
                    return element.id === currentExcecis
                })

                const last = exercisesClone.slice(-1)[0]

                if (last === exercisesClone[getIndex]) {
                    return this.setState({
                        ExcecisState: "end",
                        currentExcecis: null,
                        singleExcecis: [],
                    })
                } 
        
                const spliceExercise = exercisesClone.splice(getIndex, 1)
        
                exercisesClone.push(spliceExercise[0])
        
                const workoutscheduleData = { 
                    ...workoutschedule,
                    [workoutscheduleId]: {
                        ...workoutschedule[workoutscheduleId],
                        exercises: exercisesClone
                    }
                }

                this.setState({
                    currentExcecis: exercisesClone[getIndex].id,
                    singleExcecis: exercisesClone[getIndex],
                    workoutschedule: workoutscheduleData,
                }, () => {
                    const { currentExcecis, singleExcecis, totalExcecisCount, workout} = this.state
        
                    localforage.setItem(`${workoutscheduleId}_workoutschedule`, workoutschedule[workoutscheduleId])
                    localforage.setItem(`${workoutscheduleId}_currentExcecis`, currentExcecis)
                    localforage.setItem(`${workoutscheduleId}_singleExcecis`, singleExcecis)
                    localforage.setItem(`${workoutscheduleId}_totalExcecisCount`, totalExcecisCount)
                })

                this.getExerciseState(exercisesClone[getIndex])
            }
        }

    }

    setTimeSetDuration = (duration) => {
        this.setState({
            getTimeDuration: duration
        })
    }

    setStartDate = () => {
        this.setState({
            startDate: new Date()
        })
    }

    handleChoseNext = () => {
        this.setState({
            showSetList: 'actionNextChose'
        })
    }

    handleStopBreak = () => {
        this.setState({
            break: false,
        })
    }

    handleVideoClick = () => {
        this.setState({
            showSetList: 'video'
        })
    }

    handleSetValuesClick = () => {
        this.setState({
            showSetList: 'setValues'
        })
    }
    
    handleActionClick = () => {
        this.setState({
            showSetList: 'action'
        })
    }

    handleActionNextClick = () => {
        this.setState({
            showSetList: 'actionNext'
        })
    }

    handleWorkoutClick = () => {
        this.setState({
            showSetList: 'workout'
        })
    }

    handleInfoClick = () => {
        this.setState({
            showSetList: 'info'
        })
    }

    handleListClick = () => {
        this.setState({
            showSetList: 'list'
        })
    }

    endSet = () => {
        this.startBreak()
    }

    getNextExcecis = () => {
        this.setState({
            showSetList: 'list'
        })

        const { workoutscheduleId } = this.state

        const workoutId = workoutscheduleId === null ? this.props.exerciseId : workoutscheduleId

        const { exercises } = this.state.workoutschedule[workoutId]

        let getIndex = exercises.findIndex((element)  => {
            return element.id === this.state.currentExcecis
        }) + 1

        const last = exercises.slice(-1)[0]

        if (last === exercises[getIndex - 1]) {
            return this.setState({
                ExcecisState: "end",
                currentExcecis: null,
                singleExcecis: [],
            })
        }

        this.setState({
            currentExcecis: exercises[getIndex].id,
            singleExcecis: exercises[getIndex],
            totalExcecisCount: (this.state.totalExcecisCount + 1)
        }, () => {
            const { currentExcecis, singleExcecis, totalExcecisCount} = this.state

            localforage.setItem(`${workoutId}_currentExcecis`, currentExcecis)
            localforage.setItem(`${workoutId}_singleExcecis`, singleExcecis)
            localforage.setItem(`${workoutId}_totalExcecisCount`, totalExcecisCount)
        })

        this.getExerciseState(exercises[getIndex])
    }

    getExercise = async () => {

        localforage.getItem(`workoutscheduleKeys`).then((data) => {
            const workoutKeys = data
            if (workoutKeys !== null) {
                if (workoutKeys.length === 1) {
                    this.loadData(workoutKeys[0])
                } else {
                    workoutKeys.map((id) => {
                        localforage.getItem(`${id}_workoutschedule`).then((workout) => {
                            this.setState({
                                workoutschedule: {
                                    ...this.state.workoutschedule, [id]: workout
                                }
                            })
                        })
                    })
                }
            } else {
                ExceciseAdapterWrapper.getWorkout().then(({workoutschedule}) => {
                    let workoutKeys = []

                    Object.keys(workoutschedule).map((workout) => {
                        let id = workoutschedule[workout].id

                        localforage.setItem(`${id}_workoutschedule`, workoutschedule[workout]);
                        localforage.setItem(`${id}_currentExcecis`, workoutschedule[workout].exercises[0].id)
                        localforage.setItem(`${id}_singleExcecis`, workoutschedule[workout].exercises[0])
                        localforage.setItem(`${id}_totalExcecisCount`, 1)

                        workoutKeys.push(id)
                    })
        
                    localforage.setItem(`workoutscheduleKeys`, workoutKeys)
        
                    this.setState({
                        isLoaded: true,
                        workoutschedule,
                    }, () => {
                        if (workoutKeys.length === 1) {
                            let first = workoutKeys[0]

                            this.setState({
                                totalExcecis: workoutschedule[first].exercises.length,
                                singleExcecis: workoutschedule[first].exercises[0],
                                currentExcecis: workoutschedule[first].exercises[0].id
                            })
            
                            this.getExerciseState(workoutschedule[first].exercises[0])
                        }
                    })
                })
            }
        })
    }

    getNextExerciseSet = () => {
        this.setState({
            showSetList: 'workout'
        })

        const doneSetNumber = this.state.getSetsDone + 1

        if (this.state.singleExcecisSets.length === doneSetNumber) {
           this.getNextExcecis()
        } else {
            this.setState({
                currentExcecisSets: this.state.singleExcecisSets[doneSetNumber],
                getSetsDone: doneSetNumber
            })
          
        }
    }

    getExerciseState = async (Exercise) => {
        this.setState({
            singleExcecisSets: [],
            getSetsDone: 0,
            setsLoad: false,
        })

        ExceciseAdapterWrapper.getWorkoutSets(Exercise._joinData.id).then(({data}) => {
            const { exercisesWorkoutschedule } = data
            const single = exercisesWorkoutschedule.exercises_workoutschedules_sets
            const {0 : first} =  single

            this.setState({
                currentExcecisSets: first,
                setsLoad: true,
                singleExcecisSets: single,
            })
        })
    }

    getDateToString = () => {
        const dateNow = new Date();

        const monthNames = [
            "Januar", "Februar", "März",
            "April", "Mai", "Juni", "Juli",
            "August", "September", "Oktober",
            "November", "Dezember"
        ];

        return dateNow.getDate() + '.' + monthNames[dateNow.getMonth()] + '.' + dateNow.getFullYear()
    }

    setDateFormat = (date) => {
        const dateNow = new Date(date);

        return dateNow.getFullYear() 
            + '-' + (dateNow.getMonth() + 1)
            + '-' + dateNow.getDate() 
            + ' ' + ((dateNow.getHours() < 10)? '0' + dateNow.getHours() : dateNow.getHours())
            + ':' + ((dateNow.getMinutes() < 10)? '0' + dateNow.getMinutes() : dateNow.getMinutes())
            + ':' + ((dateNow.getSeconds() < 10)? '0' + dateNow.getSeconds() : dateNow.getSeconds())
    }

    getFrontendTime = (time) => {
        let second = Math.floor((time % (1000 * 60)) / 1000);;
        let minute = Math.floor((time/1000) / 60)

        if (second <= 0) {
            return minute
        }

        if (second < 10) {
            second = '0' + second
        }

        return minute + '.' + second
    }

    handleBtnMainClick = id => {
        this.loadWorkoutData(id)
    }

    loadData = (id) => {
        localforage.getItem(`${id}_workoutschedule`).then((workout) => {

            if (workout !== null && workout.exercises.length !== 0) {
                localforage.getItem(`${id}_currentExcecis`, (err, currentExcecis) => {
                    if (currentExcecis !== null) {
                        this.setState({
                            currentExcecis
                        })
                    }
                });

                localforage.getItem(`${id}_singleExcecis`, (err, singleExcecis) => {
                    if (singleExcecis !== null) {
                        this.setState({
                            singleExcecis
                        })
                        this.getExerciseState(singleExcecis)
                    }
                });

                localforage.getItem(`${id}_totalExcecisCount`, (err, totalExcecisCount) => {
                    if (totalExcecisCount !== null) {
                        this.setState({
                            totalExcecisCount
                        })
                    }
                });

                const workoutschedule = {...this.state.workoutschedule, [id]: workout}

                 this.setState({
                    isLoaded: true,
                    totalExcecis: workout.exercises.length,
                    workoutschedule
                })
            } else {
                this.getExercise()
            }
        })
    }

    resetWorkoutschedule = () => {
        const { workoutscheduleId } = this.state

        localforage.iterate(function(value, key) {
            let keyData = key.split('_')

            if (Number(keyData[0]) === Number(workoutscheduleId)) {
                localforage.removeItem(key)
            }
        }, () => {
            ExceciseAdapterWrapper.getSingleWorkout(workoutscheduleId).then(({workoutschedule}) => {

                if (workoutschedule !== null) {
                    localforage.setItem(`${workoutscheduleId}_workoutschedule`, workoutschedule);
                    localforage.setItem(`${workoutscheduleId}_currentExcecis`, workoutschedule.exercises[0].id)
                    localforage.setItem(`${workoutscheduleId}_singleExcecis`, workoutschedule.exercises[0])
                    localforage.setItem(`${workoutscheduleId}_totalExcecisCount`, 1)

                    this.setState({
                        ExcecisState: 'main',
                        workoutschedule: {
                            ...this.state.workoutschedule , [workoutscheduleId]: workoutschedule
                        }
                    })
                }
            })
        })
    }

    loadWorkoutData = id => {
        const { exerciseId } = this.props
        const workoutId = id !== null ? id : exerciseId

        this.setState({
            workoutscheduleId: workoutId
        })

        if (workoutId === null) {
            this.getExercise()
        } else {
            this.loadData(workoutId)
        }
    

        this.setState({
            isLoaded: true,
        })
    }

    componentDidMount = async () => {
        this.loadWorkoutData(null)
    }

    render() {
        const { children } = this.props
        const { isLoaded } = this.state

        if (!isLoaded) {
            return <Loading additionalClass="" />
        }

        return (
            <ExceciseContext.Provider
                value={{
                    state: this.state,
                    endSet: this.endSet,
                    resetWorkoutschedule: this.resetWorkoutschedule,
                    handleInfoClick: this.handleInfoClick,
                    handleVideoClick: this.handleVideoClick,
                    getDateToString: this.getDateToString,
                    handleSetValuesClick: this.handleSetValuesClick,
                    getFrontendTime: this.getFrontendTime,
                    handleSetPushClick: this.handleSetPushClick,
                    setDateFormat: this.setDateFormat,
                    handleChoseNext: this.handleChoseNext,
                    setTimeSetDuration: this.setTimeSetDuration,
                    setStartDate: this.setStartDate,
                    handleStopBreak: this.handleStopBreak,
                    handleActionNextClick: this.handleActionNextClick,
                    handleActionClick: this.handleActionClick,
                    saveDateWorkout: this.saveDateWorkout,
                    getNextExerciseSet: this.getNextExerciseSet,
                    handleWorkoutClick: this.handleWorkoutClick,
                    handleListClick: this.handleListClick,
                    removeBreak: this.removeBreak,
                    isbreakRefresh: this.isbreakRefresh,
                    handleBtnMainClick: this.handleBtnMainClick,
                    getNextExcecis: this.getNextExcecis,
                    getExerciseState: this.getExerciseState
                }}
            >
                {children}
            </ExceciseContext.Provider>
        )
    }
}
