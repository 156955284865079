import React, { Component } from 'react'
import { ExceciseContext } from "../../../context/ExceciseProvider"

import { Row, Col } from 'react-bootstrap';
import { Link } from "@reach/router";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClipboardListCheck, faInfo } from "@fortawesome/pro-solid-svg-icons"

import './WorkoutWrapper.scss'


class WorkoutWrapper extends Component {

    render() {
        return (
            <ExceciseContext.Consumer>
                {({ state }) => {

                    const { workoutschedule } = state

                    const workout = Object.keys(workoutschedule)

                    if (workout.length === 0) {
                        return (
                             <div className="step-training mt-1">
                                <Row>
                                    <Col xs={8}>
                                        <h5 className="font-weight-normal">Kein Aktiver Trainingsplan</h5>
                                        <p className="step-training-text">Momentan keine aktiven Trainingspläne.</p>    
                                    </Col>
                                    <Col xs={4} className="step-icon d-flex justify-content-center">
                                        <FontAwesomeIcon className="align-self-center" icon={faInfo} />
                                    </Col>
                                </Row>
                            </div>
                        )
                    } else {
                        return (
                            <React.Fragment>
                                 <div className="step-training mt-1">
                                     <Link to="/workout">
                                        <Row>
                                            <Col xs={8}>
                                                <h5 className="font-weight-normal">Dein Trainingsplan</h5>
                                                <p className="step-training-text">Starte jetzt mit deinem <strong>Trainingsplan</strong> und schließe diesen ab.</p>    
                                            </Col>
                                            <Col xs={4} className="step-icon d-flex justify-content-center">
                                                <FontAwesomeIcon className="align-self-center" icon={faClipboardListCheck} />
                                            </Col>
                                        </Row>
                                    </Link>
                                </div>
                            </React.Fragment>
                        )

                    }
                }}
            </ExceciseContext.Consumer>
           
        )
    }
}
WorkoutWrapper.contextType = ExceciseContext; 
export default WorkoutWrapper