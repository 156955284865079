import React, { Component } from "react"
import ExerciseSetSingleView from "./Container/Input"

import Loading from "../../../../services/Loading"

import ExerciseIndex from './Container/Index'
import ExerciseVideo from "./Container/Video"
import ExerciseInfo from "./Container/Info"
import ExerciseWorkout from "./Container/Workout"
import ExerciseAction from "./Container/Action"
import ExerciseActionNext from "./Container/ActionNext"
import ExerciseChoseAction from "./Container/ChoseAction"


export default class MainContainer extends Component {

    getSetListOrSingle = () => {
        const {
            state,
            endSet,
            getNextExcecis,
            handleVideoClick,
            handleListClick,
            handleInfoClick,
            handleActionClick,
            saveDateWorkout,
            handleSetValuesClick,
            getNextExerciseSet,
            handleActionNextClick,
            handleSetPushClick,
            handleStopBreak,
            handleChoseNext,
            setTimeSetDuration,
            setStartDate,
            setDateFormat,
            getFrontendTime
        } = this.props.excecis;

        const { name } = state.singleExcecis

        if (state.showSetList === 'list') {
            if (state.setsLoad === true) {
                return (
                    <ExerciseIndex 
                        sets={state.singleExcecisSets}
                        singleExcecis={state.singleExcecis}
                        setsLoad={state.setsLoad}
                        endSet={endSet}
                        name={name}
                        handleActionNextClick={handleActionNextClick}
                        handleInfoClick={handleInfoClick}
                        handleVideoClick={handleVideoClick}
                        getFrontendTime={getFrontendTime}
                    />
                )
            } else {
                return (
                    <Loading additionalClass={'loading-workout-index'}/>
                )
            }
          
        } else if (state.showSetList === 'video') {
            return (
                <ExerciseVideo 
                    handleListClick={handleListClick}
                />
            )
        } else if (state.showSetList === 'info') {
            return (
                <ExerciseInfo 
                    handleListClick={handleListClick}
                />
            )
        } else if (state.showSetList === 'workout') {
            return (
                <ExerciseWorkout
                    currentExcecisSets={state.currentExcecisSets}
                    getSetsDone={state.getSetsDone}
                    saveDateWorkout={saveDateWorkout}
                    handleListClick={handleListClick}
                    handleActionClick={handleActionClick}
                    setTimeSetDuration={setTimeSetDuration}
                    endSet={endSet}
                    singleExcecis={state.singleExcecis}
                    setStartDate={setStartDate}
                    handleStopBreak={handleStopBreak}
                    getFrontendTime={getFrontendTime}
                />
            )
        } else if (state.showSetList === 'action') {
            return (
                <ExerciseAction
                    handleListClick={handleListClick}
                    getNextExerciseSet={getNextExerciseSet}
                    handleSetValuesClick={handleSetValuesClick}
                    setDateFormat={setDateFormat}
                    startDate={state.startDate}
                    singleSet={state.currentExcecisSets}
                />
            )
        } else if (state.showSetList === 'setValues') {
            return (
                <ExerciseSetSingleView
                    sets={state.singleExcecisSets}
                    singleSet={state.currentExcecisSets}
                    singleExcecis={state.singleExcecis}
                    startDate={state.startDate}
                    setsLoad={state.setsLoad}
                    getSetsDone={state.getSetsDone}
                    endSet={endSet}
                    getNextExerciseSet={getNextExerciseSet}
                    getTimeDuration={state.getTimeDuration}
                    name={name}
                    getNextExcecis={getNextExcecis}
                    handleInfoClick={handleInfoClick}
                    handleVideoClick={handleVideoClick}
                    setDateFormat={setDateFormat}
                />
            )
        } else if (state.showSetList === 'actionNext') {
            return (
                <ExerciseActionNext
                    handleListClick={handleListClick}
                    handleChoseNext={handleChoseNext}
                    handleSetPushClick={handleSetPushClick}
                />
            )
        } else if (state.showSetList === 'actionNextChose') {
            return (
                <ExerciseChoseAction
                    handleListClick={handleListClick}
                    getNextExcecis={getNextExcecis}
                />
            )
        }
    }

    componentDidMount () {
        const { handleBtnMainClick, state: { currentExcecis } } = this.props.excecis;

        if (currentExcecis === null) {
            handleBtnMainClick(null)
        }
    }

    render() {
        return (
            this.getSetListOrSingle()
        )
    }
}