import React, { Component } from "react"

export default class Video extends Component {

    constructor(props) {
        super(props)
        this.state = { 
            activeSets: [],
            singleActive: 0
        }
    }


    render() {
        const { handleListClick } = this.props

        return (
            <div className="col-12 p-0">
                <div className="col-12 text-left back-btn-list">
                    <div 
                        onClick={handleListClick}
                    >
                        Zurück
                    </div>
                </div>
                <p className="mt-1 mb-1 video-text">Schau dir das Training genau an. Sorgfältiges ausführen der Übung steigert die Effektivität der Einheit.</p>
                <video
                    src="https://assets.polestar.com/video/test/polestar-1_09.mp4"
                    type="video/mp4"
                    controls
                    className="video-fluid mt-1"
                />
            </div>
        )
    }
}
