import React, { Component } from "react"

import { Modal } from 'react-bootstrap';
import CountdownClock from "./CountdownClock"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes} from "@fortawesome/pro-light-svg-icons"

export default class CoundownModal extends Component {

    render() {
        const { show, setModalHide } = this.props

        const millis = Date.now() - this.props.seconds 
        const time = (30 - Math.floor(millis/1000))

        return (
            <React.Fragment>
                <Modal
                    show={show}
                    onHide={setModalHide}
                    dialogClassName="modal-100w modal-100h"
                    className="modal-timer"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Body className="d-flex align-content-center flex-wrap wmc-body">
                        <div className="close-btn-workout-modal" onClick={setModalHide}>
                            <FontAwesomeIcon icon={faTimes} />
                        </div>
                        <CountdownClock 
                            font={"Roboto, sans-serif"} 
                            fontSize={"auto"} 
                            color={"#ddb56e"} 
                            size={400} 
                            height={"150px"}
                            seconds={time} 
                            showMilliseconds={true}
                            setSeconds={this.props.setSeconds}
                            breakRefresh={this.props.breakRefresh}
                            isbreakRefresh={this.props.isbreakRefresh}
                            onComplete={this.props.onComplete}
                            addClass={"col-12 timer-canvas timer-canvas-modal d-flex justify-content-center"}
                        />
                    </Modal.Body>
                </Modal>
          </React.Fragment>
        )
    }
}
