import React, { Component } from "react"

import { Button } from 'react-bootstrap';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faWifiSlash, faRedoAlt} from "@fortawesome/pro-light-svg-icons"

import './NoWifi.scss'

export default class NoWifi extends Component {

    handleClick = () => {
        this.props.handleClick()
    }


    render() {
        return (
            <div className="col-12 text-center">
                <div className="no-wifi-text">
                    <FontAwesomeIcon icon={faWifiSlash} />
                    <p>Der Satz konnte nicht abgespeichert werden, bitte versuch es erneut!</p>
                </div>
                <Button
                    className="w-100 back-btn btn-next-set mt-1"
                    onClick={this.handleClick} 
                >
                    <FontAwesomeIcon icon={faRedoAlt} />
                </Button>
            </div>
        )
    }
}
