import React, {  useContext } from "react"
import AuthContext from "../context/AuthProvider";
import Loading from "./Loading";
import Login from "./Login/Login"
import Header from '../Workout/Header/Header'
import Footer from '../Workout/Footer/Footer'

import { ExceciseProvider } from "../context/ExceciseProvider"

const Route = ({ path, authenticated, component: Component, ...props}) => {
    const { authentication } = useContext(AuthContext)

    // TODO: check if user is allowed to see the route!

    if (authenticated) {
        if (authentication === null) {
            return <div className="w-100 h-100 p-6">
                <Loading/>
            </div>
        }

        if (!authentication) {
            return (
                <div className="container-fluid workout-login h-100">
                    <Login />
                    <Footer />
                </div>
            )
        }
    }
    return (
        <React.Fragment>
            <Header />
            <div className="container">
                <ExceciseProvider exerciseId={props.exerciseId ? props.exerciseId : null}>
                    <Component path={path} />
                </ExceciseProvider>
            </div>
            <Footer />
        </React.Fragment>
    )
}

export default Route