import React, { Component } from "react"

import CountdownClock from "./Countdown/CountdownClock"
import CoundownModal from "./Countdown/CoundownModal"

import './Break.scss'

export default class Break extends Component {

    constructor(props) {
        super(props)

        this.state = {
            modal: false,
            startDate: null,
        }
    }

    setModalShow = () => {
        console.log(this.state)
        this.setState({
            modal: true,
        })
    }

    setModalHide = () => {
        this.setState({
            modal: false,
        })
    }

    componentDidMount() {
        this.setState({
            startDate: Date.now(),
        })
    }

    getCountdownTimer = () => {

        return (
            <CountdownClock 
                font={"Roboto, sans-serif"} 
                fontSize={"auto"} 
                color={"#ddb56e"} 
                size={400} 
                seconds={30} 
                showMilliseconds={true}
                setSeconds={this.setSeconds}
                breakRefresh={this.props.breakRefresh}
                isbreakRefresh={this.props.isbreakRefresh}
                onComplete={this.props.removeBreak}
                height={"60px"}
                addClass={"col-12 timer-canvas d-flex justify-content-left"}
            />
        )
    }

    getModal= () => {
        if (this.state.modal === true) {
            return (
                <CoundownModal 
                    show={this.state.modal}
                    setModalHide={this.setModalHide}
                    getCountdownTimer={this.getCountdownTimer}
                    seconds={this.state.startDate} 
                    setSeconds={this.setSeconds}
                    breakRefresh={this.props.breakRefresh}
                    isbreakRefresh={this.props.isbreakRefresh}
                    onComplete={this.props.removeBreak}
                />
            )
        }
    }

    render() {

        return (
            <React.Fragment>
                <div className="col-12 p-0">
                   {this.getModal()}
                    <div
                        onClick={this.setModalShow}
                        className="set-timer"
                    >
                    {this.getCountdownTimer()}
                        <h3 className="font-weight-light">Erholung</h3>
                        <h6>{this.props.name}</h6>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
