import React, { Component } from "react"
import { Form, FormGroup, Label, Input, Button } from "reactstrap"
import { Image } from 'react-bootstrap';

import StatusContext from '../../context/StatusProvider'

import { Auth } from "@interweberde/prima-core"

import './Login.scss'

class Login extends Component {

    constructor(props) {
        super(props)

        this.state = {}
    }

    componentDidMount = async () => {
        this.setState({
            showLogin: true,
        })
    }

    handleInputChange = event => {
        const { target } = event
        this.setState({
            [target.name]: target.value,
        })
    }

    handleSubmit = async event => {
        event.preventDefault()

        const { username, password } = this.state
        const success = await Auth.login(username, password)

        if (!success) {
            this.setState({
                error:
                    "Bitte überprüfen Sie die eingegebenen Anmeldedaten.",
            })
        } else {
            const { setStatus } = this.context

            setStatus({
                data: 'Benutzer Anmeldung',
                path: 'login'
            })
        }
    }

    closeError = () => {
        this.setState({
            error: undefined,
        })
    }

    getLoginAlert = () => {
        const { error } = this.state
        
        if (error) {
            return (
                <div
                    className="alert alert-info"
                    role="alert"
                    onClick={this.closeError}
                >
                    {error}
                </div>
            )
        }

        return ""
    }

    render() {
        return (
            <div className="container d-flex h-100">
                <div className="w-100 justify-content-center align-self-center">
                    <div className="col-12 text-center">
                        <Image 
                            src="/img/Workout.png" 
                            fluid 
                        />
                    </div>
                    <div className="col-12 mt-4">
                        {this.getLoginAlert()}
                        <Form 
                            onSubmit={this.handleSubmit}
                        >
                            <FormGroup>
                                <Label for="username">Benutzername</Label>
                                <Input
                                    type="text"
                                    name="username"
                                    id="username"
                                    autoComplete="username"
                                    placeholder="Benutzername"
                                    autoCapitalize="off"
                                    onChange={this.handleInputChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="password">Passwort</Label>
                                <Input
                                    type="password"
                                    name="password"
                                    id="password"
                                    autoComplete="password"
                                    placeholder="Passwort"
                                    autoCapitalize="off"
                                    onChange={this.handleInputChange}
                                />
                            </FormGroup>
                            <Button type="submit" className="btn-primary mt-2">Anmelden</Button>
                        </Form>
                    </div>
                </div>
            </div>
        )
    }
}

Login.contextType = StatusContext;
export default  Login