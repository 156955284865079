import { ApiAdapter } from '@interweberde/prima-core'

export default class ExceciseAdapter extends ApiAdapter {

    async getWorkout(cancelerReceiver) {
        const  { data: {workoutschedule }}  = await this._get(`workout/api/Workoutschedules/getworkoutschedulelist`, {}, cancelerReceiver)

        return {
            workoutschedule
        }
    }

    async getSingleWorkout(id, cancelerReceiver) {
        const  { data: {workoutschedule }}  = await this._get(`workout/api/Workoutschedules/getworkoutschedule/${id}`, {}, cancelerReceiver)

        return {
            workoutschedule
        }
    }

    async getWorkoutSets(id, cancelerReceiver) {
        const  { data }  = await this._get(`workout/api/ExercisesWorkoutschedules/view/${id}`, {}, cancelerReceiver)

        return {
            data
        }
    }

    async setWorkoutSetData(content, cancelerReceiver) {
        console.log(content)
        const  { data }  = await this._post(`workout/api/TrainingsessionsSets/add`, content, {}, cancelerReceiver)

        console.log(data)

        return {
            data
        }
    }
}