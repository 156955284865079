import React, { Component } from "react"

import { Image, Button } from 'react-bootstrap';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDumbbell } from "@fortawesome/pro-solid-svg-icons"

import ExerciseBreak from "./MainContainer/Container/Break"
import MainContainer from './MainContainer/MainContainer'

import './Main.scss'

export default class Main extends Component {

    constructor(props) {
        super(props)
        this.state = {
            error: null,
            isLoaded: false,
            count: 30
        }
    }

    getBreakOrSet = (breakValue) => {
        const { removeBreak, isbreakRefresh, state } = this.props.exercise;

        const { name } = state.singleExcecis

        if (breakValue) {
            return ( 
                <ExerciseBreak 
                    removeBreak={removeBreak} 
                    breakRefresh={state.breakRefresh}
                    isbreakRefresh={isbreakRefresh}
                    name={name}
                />
            )
        } else {
            return (
                <div className="col-12 p-0">
                    <div className="exercis-head">
                        <h3 className="worklfow-excecis-h3">{name}</h3>
                        <h5><FontAwesomeIcon icon={faDumbbell} />  {state.totalExcecisCount} von {state.totalExcecis} Übungen </h5>
                    </div>
                </div>
            )
        }
    }

    getStartButton = () => {
        const { handleWorkoutClick, state } = this.props.exercise;
        if (
            state.showSetList !== 'workout' 
            && state.showSetList !== 'action'
            && state.showSetList !== 'setValues'
            && state.showSetList !== 'actionNext'
            && state.showSetList !== 'actionNextChose'
        ) {
            return (
                <Button 
                onClick={handleWorkoutClick} 
                className="wsb-list-btn">
                    Starten
                </Button>
            )
        }
    }

    render() {
        const { state, getDateToString } = this.props.exercise

        return (
            <>
                <div className="workout-wrapper">
                    <div className="datetime-main">
                        {getDateToString()}
                    </div>
                    <div className="row">
                        {this.getBreakOrSet(state.break)}
                    </div>
                    <div className="row">
                        <div className="col-12 p-0 exercis-intro">
                            <div className="back-wrapper">
                                <Image src="/img/intro-small.png" fluid />
                                {this.getStartButton()}
                            </div>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <MainContainer excecis={this.props.exercise} />
                    </div>
                </div>
            </>
        )
    }
}
