import React, { Component } from 'react'

import { Row, Col } from 'react-bootstrap';
import { Link } from "@reach/router";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAnalytics } from "@fortawesome/pro-solid-svg-icons"

import './StatistikWrapper.scss'


export default class WorkoutWrapper extends Component {

    render() {
        return (
            <React.Fragment>
                <div className="step-training mt-1">
                    <Link to="/statistik">
                        <Row>
                            <Col xs={4} className="step-icon d-flex justify-content-center">
                                <FontAwesomeIcon className="align-self-center" icon={faAnalytics} />
                            </Col>
                            <Col xs={8}>
                                <h5 className="font-weight-normal">Deine Statistik</h5>
                                <p className="step-training-text">Schau dir deine <strong>Statistik</strong> an und verbesser dich daran.</p>
                            </Col>
                        </Row>
                    </Link>
                </div>
            </React.Fragment>
        )
    }
}
