import { ApiAdapter } from '@interweberde/prima-core'

export default class UserAdapter extends ApiAdapter {

    async setProfileData(id, content, cancelerReceiver) {
        console.log(content)
        const  { data }  = await this._post(`core/users/edit/${id}`, content, {}, cancelerReceiver)

        return {
            data
        }
    }

    async setOnlineStatus(content, cancelerReceiver) {
        const  { data }  = await this._post(`core/UsersOnline/setOnline`, content, {}, cancelerReceiver)

        return { data }
    }
}