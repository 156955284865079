import React, { Component } from "react"

import { Form } from 'react-bootstrap';

import './Input.scss'

export default class WdhOption extends Component {

    handleOptionChange = (e) => {
        this.props.setValueData(e.target.value)
    }

    render() {
        const { set, handleSubmit, stateSet, handleChange, singleExcecis } = this.props

        const dataArray = singleExcecis.value.split(',')

        return (
            <Form className="col-12 d-flex mb-2" key={set.id} name="TrainingsessionsSets" onSubmit={handleSubmit}>
                <Form.Group className="d-flex w-100 mb-0 d-flex flex-column" controlId="formWorkoutSetWdh">
                    <Form.Label className="label-set">Wiederholungen</Form.Label>
                    <Form.Control
                        name="wdh"
                        className="wi-set-input pl-3 text-center flex-fill"
                        type="number"
                        defaultValue={set.wdh}
                        isInvalid={stateSet.error.wdh}
                        onChange={handleChange}
                        onClick={this.handleFocus}
                        onKeyDown={handleChange}
                        pattern="/^-?\d*[.,]?\d*$/"
                    />
                </Form.Group>
                <span className="pr-2 pl-2 d-flex align-content-center flex-wrap"><br/>x</span>
                <Form.Group className="d-flex w-100 mb-0 flex-fill d-flex flex-column" controlId="formWorkoutSetSecnds">
                    <Form.Label className="label-set">Auswahl:</Form.Label>
                    <Form.Control
                        as="select"
                        defaultValue={set.value}
                        name="option"
                        onChange={this.handleOptionChange}
                    >
                        {dataArray.map((x, i) =>
                            {
                                return <option value={x} key={i} >{x}</option>
                            }
                        )}
                    </Form.Control>
                </Form.Group>
            </Form>
        )
    }
}
