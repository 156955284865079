import React, { useContext } from "react"

import { ExceciseContext } from "../../context/ExceciseProvider"
import ExerciseView from "./Main/Main"
import ExerciseEnd from "./End/End"

function Exercise() {

    const Excecise = useContext(ExceciseContext)

    return (
        <React.Fragment>
            {Excecise.state.ExcecisState === "main" ? 
            <ExerciseView exercise={Excecise} /> 
            : <ExerciseEnd exercise={Excecise} />}
        </React.Fragment>
    );
}
export default Exercise