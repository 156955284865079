import {useState, useEffect, useCallback} from "react"
import { Auth } from "@interweberde/prima-core"
import debugLib from "debug"

export const useAuth = (callee) => {
    const [authenticated, setAuthenticated] = useState(null);
    const [user, setUser] = useState(null);

    const debug = useCallback(debugLib("quadio:hooks:auth:" + callee), [callee])

    useEffect(() => {
        if (authenticated === null) {
            debug("will not register login subscriber - authentication state not determined!")
            return
        }

        if (authenticated) {
            debug("will not register login subscriber - user is logged in!")
            return
        }

        const unsubscribe = Auth.subscribe("login", (success) => {
            if (!success) {
                return
            }

            debug("user logged in")

            setAuthenticated(true)
        })

        return () => {
            unsubscribe()
            debug("unsubscribed from login event")
        }
    }, [authenticated, debug])

    useEffect(() => {
        if (authenticated === null) {
            debug("will not register logout subscriber - authentication state not determined!")
            return
        }

        if (!authenticated) {
            debug("will not register logout subscriber - user is not logged in!")
            return
        }

        const unsubscribe = Auth.subscribe("logout", (success) => {
            if (!success) {
                return
            }

            debug("user logged out")

            setAuthenticated(false)
        })

        return () => {
            unsubscribe()
            debug("unsubscribed from logout event")
        }
    }, [authenticated, debug])

    useEffect(() => {
        let cancel = () => {}

        Auth.validateAuthentication((canceler) => {
            cancel = canceler
        }).then(isAuthenticated => {
            // check if request was canceled
            if (isAuthenticated === null) {
                return
            }

            debug(`check authenticated: user is ${isAuthenticated ? "authenticated" : "unauthenticated"}`)

            setAuthenticated(isAuthenticated)
        });

        return () => {
            debug("cancel auth check!")
            cancel()
        }
    }, [debug]);

    useEffect(() => {
        let unmounted = false
        let cancel = () => {}
        const unsubscribe = Auth.subscribe("userDidUpdate", async () => {
            if (unmounted) {
                return
            }

            const user = await Auth.getUser(false, (canceler) => {
                cancel = canceler
            })

            if (unmounted) {
                return
            }

            debug("user did update %O", user)
            setUser(user)
        })

        const cleanup = () => {
            unmounted = true
            cancel()
            unsubscribe()
        }

        debug("subscribed to user update events")

        return () => {
            cleanup()
            debug("unsubscribed from user update event")
        }
    }, [debug])

    return [authenticated, user, setUser];
}