import React from 'react';

import { Row, Col } from 'react-bootstrap';
import { Link } from "@reach/router"

import './Footer.scss'

function Footer() {

  return (
    <footer className="container-fluid footer">
        <Row>
            <Col xs={6} className="text-right" >
                <Link to="impresssum">
                    Impressum
                </Link>
            </Col>
            <Col xs={6} className="text-left" >
                <Link to="datenschutz">
                    Datenschutz
                </Link>
            </Col>
        </Row>
    </footer>
        
  );
}

export default Footer