import React, { useContext } from "react"

import { ExceciseContext } from "../../context/ExceciseProvider"
import WorkoutSingle from './WorkoutSingle/WorkoutSingle'

import { Row, Col } from 'react-bootstrap';

import './Workout.scss'

function Workout() {

    const { state: { workoutschedule }, handleBtnMainClick, getDateToString } = useContext(ExceciseContext)

    return (
        <React.Fragment>
            <Row>
                <Col className="mt-2">
                    <h2 className="font-weight-light">
                        {Object.keys(workoutschedule).length < 1 ? 'Trainingsplan' : 'Trainingspläne'}
                    </h2>
                </Col>
                <Col xs={12}>
                    <div className="workout-today">
                        {getDateToString()}
                    </div>
                </Col>
            </Row>
            {
                Object.keys(workoutschedule).map((key) => (
                    <WorkoutSingle
                        workout={workoutschedule[key]} 
                        startClick={handleBtnMainClick} 
                        key={key} 
                    />
                ))
            }
        </React.Fragment>
    );
}
export default Workout