import React, { Component } from "react"

import { Button } from 'react-bootstrap';

import InputWdhValue from './Type/Input/WdhValue'
import InputMin from './Type/Input/Min'
import InputMinWdh from './Type/Input/MinWdh'
import InputWdh from './Type/Input/Wdh'
import InputWdhOption from './Type/Input/WdhOption'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons"

import ErrorNoWifi from './Error/NoWifi'

import './Input.scss'

import { ExceciseAdapterWrapper } from '../../../../../context/ExceciseProvider'

const components = {
    1: InputWdhValue,
    2: InputMin,
    3: InputMinWdh,
    4: InputWdh,
    5: InputWdhOption,
};

export default class Input extends Component {

    constructor(props) {
        super(props)
        this.state = {
            error: {
                wdh: false,
                value: false,
            },
            set: {
                wdh: null,
                value: null,
                enddate: null,
                startdate: null
            },
            done: false,
            active: false,
            wifi: true,
            loading: false
        }
    }

    componentDidMount () {
        const { singleExcecis, getTimeDuration } = this.props

        const typeKey = (typeof singleExcecis.type !== 'undefined') ? singleExcecis.type : 1;

        let setValue = {
            wdh: this.props.singleSet.wdh,
            value: this.props.singleSet.value,
            enddate: this.props.setDateFormat(new Date()),
            startdate: this.props.setDateFormat(this.props.startDate)
        }

        if (typeKey === 2 || typeKey === 3) {
            setValue = {
                ...setValue,
                value: getTimeDuration
            }
        }

        this.setState({
           set: setValue
        })
    }

    handleSubmit = async event => { 
        event.preventDefault()

        this.setState({
            done: true,
            loading: true
        })
        const { singleSet } = this.props

        const { wdh, value, enddate, startdate } = this.state.set

        const content = {
            wdh: wdh,
            value: value,
            enddate: enddate,
            startdate: startdate,
            exercises_workoutschedules_set_id: singleSet.id
        }

        ExceciseAdapterWrapper.setWorkoutSetData(content).then(() => {
            this.setState({
                wifi: true,
                loading: false
            })
            this.props.getNextExerciseSet()
        })
    }

    setValueData = (data) => {
        const { set } = this.state
        const update = { ...set , value: data }

        this.setState({
            set: update
        })
    }

    handleChange = (e) => {
        const { set } = this.state

        const updateSet = {
            ...set,
            [e.target.name]: Math.trunc(e.target.value),
        }

        this.setState({
            set: updateSet
        })
    }

    handleFocus = (event) => {
        event.target.select();
    }

    getInputType = () => {
        const { singleSet, singleExcecis, getTimeDuration } = this.props

        const typeKey = (typeof singleExcecis.type !== 'undefined') ? singleExcecis.type : 1;

        const ComponentTypeName = components[typeKey]

        return (
            <ComponentTypeName
                set={singleSet}
                stateSet={this.state}
                singleExcecis={singleExcecis}
                handleSubmit={this.handleSubmit}
                handleChange={this.handleChange}
                getTimeDuration={getTimeDuration}
                setValueData={this.setValueData}
            />
        )
    }

    getNextBtn = () => {
        if (this.state.loading === false) {
            return (
                <Button 
                className="btn btn-primaray btn-input-next" 
                onClick={this.handleSubmit} 
                >
                    Weiter
                </Button>
            )
        } else {
            return (
                <Button 
                className="btn btn-primaray btn-input-next" 
                >
                    <FontAwesomeIcon icon={faSpinnerThird} spin /> Weiter
                </Button>
            )
        }
    }

    render() {
        const { getSetsDone } = this.props

        if (this.state.wifi === false) {
            return (
                <ErrorNoWifi handleClick={this.handleSubmit} />
            )
        }

        return (
            <React.Fragment>
                <div className="col-12 pt-1">
                    <h6><small>Dies ist dein <strong>{getSetsDone + 1}.</strong> Trainingssatz</small></h6>
                    <h2 className="set-h2 pt-1">Eingabe:</h2>
                </div>
                {this.getInputType()}
                <div className="col-12 text-right">
                    <span><small>Weiter zum nächsten Trainingsatz</small></span>
                    <br/>
                    {this.getNextBtn()}
                </div>
            </React.Fragment>
        )
    }
}
