import React, { Component } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinnerThird } from "@fortawesome/pro-light-svg-icons"

export default class Loading extends Component {

    constructor(props) {
        super(props)

        this.state = {}
    }

    render() {
        return (
            <div
                className={`full-height d-flex justify-content-center align-items-center loading-container h-100`}
            >
                <FontAwesomeIcon icon={faSpinnerThird} spin size="5x" />
            </div>
        )
    }
}
