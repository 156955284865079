import React, { Component } from "react"

import { Link } from "@reach/router";
import { Image } from 'react-bootstrap';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDumbbell } from "@fortawesome/pro-light-svg-icons"

import './End.scss'

import localforage from '../../../services/WorkoutStorage'

export default class End extends Component {

    constructor(props) {
        super(props)
        this.state = {
            error: null,
            isLoaded: false,
        }
    }

    componentWillUnmount () {
        const { resetWorkoutschedule } = this.props.exercise;

        resetWorkoutschedule()
    }

    render() {
        const { getDateToString } = this.props.exercise

        return (
            <React.Fragment>
                <div className="workout-wrapper mt-1">
                    <div className="datetime-main">
                        {getDateToString()}
                    </div>
                    <div className="row">
                        <div className="col-12 p-0">
                            <div className="exercis-head">
                                <h3 className="worklfow-excecis-h3">Auswertung</h3>
                                <h5><FontAwesomeIcon icon={faDumbbell} /> Ende vom heutigen Workout</h5>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 p-0 exercis-intro">
                            <div className="back-wrapper">
                                <Image src="/img/intro-small-end.png" fluid />
                                <Link 
                                    to="/"
                                    className="wsb-list-btn"
                                >
                                    Zurück
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 text-center mt-3 mb-5">
                            <h4 className="font-weight-light">Workout beendet!</h4>
                        </div>
                    </div>
                   
                </div>
            </React.Fragment>
        )
    }
}
