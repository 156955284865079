import React, { Component } from "react"

export default class Info extends Component {

    constructor(props) {
        super(props)
        this.state = { 
            activeSets: [],
            singleActive: 0
        }
    }


    render() {
        const { handleListClick } = this.props

        return (
            <div className="col-12 p-0">
                <div className="col-12 text-left back-btn-list">
                    <div 
                        onClick={handleListClick}
                    >
                        Zurück
                    </div>
                </div>
                <h6 className="mt-2">Folgende Muskeln werden beantsprucht:</h6>
                <ul>
                    <li>Abduktoren</li>
                    <li>Bizeps</li>
                    <li>Rautenmuskel</li>
                </ul>
            </div>
        )
    }
}
