import React from 'react';

import { Row, Col } from 'react-bootstrap';

import { Bar } from 'react-chartjs-2'
import './WeekStatistik.scss'

function WeekStatistik() {

    const data = {
        labels: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli'],
       
        datasets: [
          {
            label: '',
            backgroundColor: 'rgba(221, 181, 110, 0.6)',
            borderColor: 'rgba(221, 181, 110, 1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(221, 181, 110, 0.8)',
            hoverBorderColor: 'rgba(221, 181, 110, 1)',
            data: [20, 25, 30, 10, 16, 23, 25, 17],
          }
        ]
      };

      const legend = {
        display: false,
      }

      const options = {
        scales: {
            xAxes: [{
                gridLines: {
                    display:false
                }   
            }]
        }
    }

    return (
        <React.Fragment>
           <Row className="mt-2">
                <Col xs={12}>
                    <h2 className="font-weight-light"><small>Deine</small> Statistik</h2>
                </Col>
            </Row>
            <Row>
                <Col className="mt-3" xs={12}>
                    <Bar
                        width={600}
                        height={400}
                        data={data}
                        options={options}
                        legend={legend}
                    />
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default WeekStatistik